import { ReactNode, useContext, useEffect } from 'react'

import { Navigation, type NavProps } from '@socar-inc/socar-design-system'
import { callApp, isWebview } from '@socar-inc/utils'
import { useAlert } from '@src/hooks/useAlert'
import { NavigationContext, NavigationProvider } from './NavigationContext'

export const NavigationBar = (props: NavProps) => {
  const navigationContext = useContext(NavigationContext)

  return (
    <Navigation
      navHeight={56}
      showBackButton={true}
      showPageTitle={true}
      pageName={navigationContext?.navigationState.pageName}
      leftUnit1={navigationContext?.navigationState.leftUnit}
      leftUnit2={null}
      rightUnit1={navigationContext?.navigationState.rightUnit}
      style={{
        left: 'auto',
        width: '100%',
        minWidth: '320px',
        maxWidth: '420px',
        zIndex: 9,
      }}
      {...props}
    />
  )
}

export const DefaultLayout = ({ children }: { children: ReactNode }) => {
  const { isDimmed } = useAlert()

  useEffect(() => {
    if (isWebview()) {
      callApp('ActionBar', {
        visible: false,
        retentionSite: ['/', process.env.NEXT_PUBLIC_SOCAR_PLAN_CLIENT_URL],
      })
    } else return
  }, [])

  return (
    <NavigationProvider>
      <div className="tw-flex tw-justify-center">
        <div className="tw-w-screen tw-min-w-[320px] tw-max-w-[420px] tw-overflow-x-hidden tw-pt-56">
          <NavigationBar />
          {children}
          {isDimmed && (
            <div className="tw-fixed tw-inset-0 tw-z-[9999] tw-bg-black tw-bg-opacity-50" />
          )}
        </div>
      </div>
    </NavigationProvider>
  )
}
