import { initRum } from '@socar-inc/utils'
import { DefaultHeader, DefaultLayout } from '@src/components/common'
import { useEffectOnce } from '@src/hooks'
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { SessionProvider } from 'next-auth/react'
import { type AppProps } from 'next/app'
import { useState } from 'react'
import { RecoilRoot } from 'recoil'
import '../styles/global.css'

function SocialSignupApp({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps) {
  const [queryClient] = useState(() => new QueryClient())

  useEffectOnce(() => {
    initRum({
      config: {
        env: process.env.NEXT_PUBLIC_DEPLOY_ENV,
        applicationId: process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID,
        clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN,
        service: 'social-signup',
        allowedTracingUrls: [
          process.env.NEXT_PUBLIC_CLIENT_URL,
          process.env.NEXT_PUBLIC_SIGNUP_SERVER_API_URL,
          process.env.NEXT_PUBLIC_DRIVER_LICENSE_SERVER_API_URL,
        ],
      },
    })
  })

  return (
    <SessionProvider session={session}>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <RecoilRoot>
            <DefaultHeader />
            <DefaultLayout>
              <Component {...pageProps} />
            </DefaultLayout>
          </RecoilRoot>
        </Hydrate>
        <ReactQueryDevtools
          initialIsOpen={process.env.NODE_ENV === 'development'}
        />
      </QueryClientProvider>
    </SessionProvider>
  )
}

export default SocialSignupApp
