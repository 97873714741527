import { L1Type, R1Type, UnitProps } from '@socar-inc/socar-design-system'
import React, { createContext, ReactNode, useContext, useState } from 'react'

export type NavigationContextType = {
  navigationState: NavigationState
  updateNavigationState: (updates: NavigationState) => void
} | null
export const NavigationContext = createContext<NavigationContextType>(null)

export type NavigationState = {
  pageName?: string
  leftUnit?: L1Type
  rightUnit?: R1Type
}

export const NavigationProvider = ({ children }: { children: ReactNode }) => {
  const [navigationState, setNavigationState] = useState<NavigationState>({
    pageName: '',
    leftUnit: null,
    rightUnit: null,
  })

  const updateNavigationState = (updates: NavigationState) => {
    setNavigationState((prevState) => ({ ...prevState, ...updates }))
  }

  return (
    <NavigationContext.Provider
      value={{ navigationState, updateNavigationState }}>
      {children}
    </NavigationContext.Provider>
  )
}
