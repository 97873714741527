import { alertState } from '@src/store/atoms'
import { useRecoilState } from 'recoil'

export const useAlert = () => {
  const [isDimmed, setIsDimmed] = useRecoilState(alertState)

  const showAlert = (message: string) => {
    setIsDimmed(true)
    setTimeout(() => {
      alert(message)
      setIsDimmed(false)
    }, 0)
  }

  return { isDimmed, showAlert }
}
